/* eslint-disable no-undef */
// const baseurl = 'http://127.0.0.1:8000/api/';
// const baseurl = 'https://damay-co.com/api/';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const baseurl = 'https://damay-co.com/backend/public/api/';

export const ApiSlice = createApi({
    reducerPath: 'ApiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: baseurl,
        credentials: 'include', // Include credentials in the request
    }),
    endpoints: (builder) => ({
        getuserData: builder.query({
            query: (token) => ({
                url: 'user',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),

        getZoneLivraison: builder.query({
            query: () => ({
                url: 'getZoneLivraison',
                method: 'GET',
            }),
            keepUnusedDataFor: 6000,
        }),

        getUserOrder: builder.query({
            query: (token) => ({
                url: 'getuserOrders',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),

        getOrders: builder.query({
            query: (token) => ({
                url: '/admin/getOrders',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),

        getOrderDetails: builder.query({
            query: ({ token, orderId }) => ({
                url: `/admin/orderDetails/${orderId}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),

        getRobotsForAdmin: builder.query({
            query: ({ token }) => ({
                url: `/admin/robots`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),


        getPiecesRechangeForAdmin: builder.query({
            query: ({ token }) => ({
                url: `/admin/PiecesRechange`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),

        getAdmins: builder.query({
            query: ({ token }) => ({
                url: `/admin/getAdmins`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),

        getSubscribers: builder.query({
            query: ({ token }) => ({
                url: `/admin/getSubscribers`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),

        getZonesLivraison: builder.query({
            query: ({ token }) => ({
                url: `/admin/get-zone-livraison`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),

        getOverViewAdmin: builder.query({
            query: ({ token }) => ({
                url: `/admin/overView`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),

        getMonthlyRevenues: builder.query({
            query: ({ token }) => ({
                url: `/admin/getMonthlyRevenues`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),

        getOrdersCountByZone: builder.query({
            query: ({ token }) => ({
                url: `/admin/getOrdersCountByZone`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),

        getCouponsAdmin: builder.query({
            query: ({ token }) => ({
                url: `/admin/getCouponsAdmin`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),
    }),
});

export const { useGetuserDataQuery,
    useGetZoneLivraisonQuery,
    useGetUserOrderQuery,
    useGetOrdersQuery,
    useGetOrderDetailsQuery,
    useGetRobotsForAdminQuery,
    useGetPiecesRechangeForAdminQuery,
    useGetAdminsQuery,
    useGetSubscribersQuery,
    useGetZonesLivraisonQuery,
    useGetOverViewAdminQuery,
    useGetMonthlyRevenuesQuery,
    useGetOrdersCountByZoneQuery,
    useGetCouponsAdminQuery
} = ApiSlice;
