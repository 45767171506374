// @ts-nocheck
import axios from "axios";
// baseURL: 'http://localhost:8000',
// baseURL: 'https://damay-co.com',
export const axiosClient = axios.create({
  baseURL: 'https://damay-co.com',
  withCredentials: true,
  withXSRFToken: true
})

export const fetchCSRFCookie = () => axiosClient.get('/sanctum/csrf-cookie');

export const checkValidityToken = (token) => axiosClient.get('/backend/public/api/validate-token', {
  headers: {
    'Authorization': `Bearer ${token}`
  }
});
export const checkAdminValidityToken = (token) => axiosClient.get('/backend/public/api/validate-Admin-token', {
  headers: {
    'Authorization': `Bearer ${token}`
  }
});
export const register = (credential) => axiosClient.post('/backend/public/api/register', credential)
export const login = (credential) => axiosClient.post('/backend/public/api/login', credential)
export const logout = (token) => {
  return axiosClient.post('/backend/public/api/logout', {}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export const logoutAdmin = (token) => {
  return axiosClient.post('/backend/public/api/admin/logout', {}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export const adminLogin = (credential) => axiosClient.post('/backend/public/api/admin/login', credential)


export const updateuser = (token, credential) => {
  return axiosClient.post('/backend/public/api/updateuser', credential, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export const addAddress = (token, credential) => {
  return axiosClient.post('/backend/public/api/add-adresse', credential, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export const updateAdress = (token, credential, addressId) => {
  return axiosClient.put(`/backend/public/api/updateAdress/${addressId}`, credential, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export const deleteAdress = (token, addressId) => {
  return axiosClient.delete(`/backend/public/api/deleteAdress/${addressId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export const setdefaultAdress = (token, addressId) => {
  return axiosClient.put(`/backend/public/api/setdefaultAdress/${addressId}`, {}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export const storeOrder = (token,orderData) => {
  return axiosClient.post('/backend/public/api/storeOrder', orderData, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export const updateStatus = (token,orderData) => {
  return axiosClient.put('/backend/public/api/admin/updateOrderStatus', orderData, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export const removeProduct = (admintoken,productType,productId) => {
  return axiosClient.delete(`/backend/public/api/admin/deleteProduct/${productType}/${productId}`, {
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};

export const updateProductStatus = (admintoken,productType,productId,status) => {
  return axiosClient.put(`/backend/public/api/admin/updateproductStatus/${productType}/${productId}`,{status},{
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};

export const updateProductField = (admintoken, productType, productId, field, value) => {
  return axiosClient.put(`/backend/public/api/admin/updateField/${productType}/${productId}`, {field,value,}, {
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};


export const uploadProductFile = (admintoken, productType, file) => {
  const formData = new FormData();
  formData.append("excel_file", file);

  return axiosClient.post(`/backend/public/api/upload/${productType}`, formData, {
    headers: {
      'Authorization': `Bearer ${admintoken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
};

export const storeimages = (admintoken, productType, productId,imageUrl) => {
  return axiosClient.post(`/backend/public/api/admin/storeImages/${productType}/${productId}`, {image_url:imageUrl}, {
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};


export const deleteImage = (admintoken,productType,imageId) => {
  return axiosClient.delete(`/backend/public/api/admin/deleteImages/${productType}/${imageId}`, {
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};

export const ContactezNous = (data) => {
  return axiosClient.post(`/backend/public/api/Contactez-Nous`, data);
};

export const Subscribe = (data) => {
  return axiosClient.post(`/backend/public/api/subscribe`, data);
};


export const editAdmin = (adminId,data,admintoken) => {
  return axiosClient.put(`/backend/public/api/admin/editAdmin/${adminId}`, data, {
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};

export const removeAdmin = (admintoken,adminId) => {
  return axiosClient.delete(`/backend/public/api/admin/removeAdmin/${adminId}`, {
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};

export const AddAdmins = (admintoken,data) => {
  return axiosClient.post(`/backend/public/api/admin/AddAdmin`,data, {
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};


export const removeZones = (admintoken,price) => {
  return axiosClient.post(`/backend/public/api/admin/removeZones`,price, {
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};

export const removeZone = (admintoken,id) => {
  return axiosClient.post(`/backend/public/api/admin/removeZone`,id, {
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};

export const addZones = (admintoken,zoneData) => {
  return axiosClient.post(`/backend/public/api/admin/AddZones`,zoneData,{
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};


export const storeFileTechnique = (admintoken,formdata) => {
  return axiosClient.post(`/backend/public/api/admin/storeFileTechnique`, formdata, {
    headers: {
      'Authorization': `Bearer ${admintoken}`,
      'Content-Type': 'multipart/form-data'
    }
  });
};


export const addCoupon = (admintoken,couponData) => {
  return axiosClient.post(`/backend/public/api/admin/storecoupons`,couponData,{
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};

export const attachproductCoupon = (admintoken,couponId,productId) => {
  return axiosClient.post(`/backend/public/api/admin/coupons/${couponId}/add-variant`,{ variant_id: productId},{
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};


export const removeattached = (admintoken,couponId,variantId) => {
  return axiosClient.delete(`/backend/public/api/admin/coupons/${couponId}/variants/${variantId}`, {
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};

export const removecoupon = (admintoken,couponId) => {
  return axiosClient.delete(`/backend/public/api/admin/removecoupons/${couponId}`, {
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};

export const updateCouponapi = (admintoken,couponId,formData) => {
  return axiosClient.put(`/backend/public/api/admin/updatecoupons/${couponId}`,formData, {
    headers: {
      'Authorization': `Bearer ${admintoken}`
    }
  });
};


export const applypromo = (code,articles) => {
  return axiosClient.post(`/backend/public/api/apply-promo`,{ promoCode: code,articles },{});
};