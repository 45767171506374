/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const baseurl = 'https://damay-co.com/backend/public/api/';

export const RobotsApiSlice = createApi({
    reducerPath: 'RobotsApiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: baseurl,
        credentials: 'include', // Include credentials in the request
    }),
    endpoints: (builder) => ({
        getRobots: builder.query({
            query: (token) => ({
                url: 'getRobots-Variant',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),

        getfileTechnical: builder.query({
            query: () => ({
                url: `pdf-path`,
                method: 'GET',
            }),
            keepUnusedDataFor: 6000,
        }),
    }),
    
});

export const { useGetRobotsQuery,useGetfileTechnicalQuery } = RobotsApiSlice;
