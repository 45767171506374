/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const baseurl = 'https://damay-co.com/backend/public/api/';

export const PiecesRechangeApiSlice=createApi({
    reducerPath: 'PiecesRechange',
    baseQuery:fetchBaseQuery({baseUrl:baseurl}),
    endpoints:(builder)=>({
        getPiecesRechange: builder.query({
            query: (token) => ({
                url: 'getPiecesRechange',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 6000,
        }),
    })
})

export const { 
    useGetPiecesRechangeQuery
} = PiecesRechangeApiSlice;